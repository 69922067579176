<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="product_code">Kode Produk</label>
                                <InputText id="product_code" v-model="forms.product_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.product_code }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.product_code" >{{ errorAdd.product_code[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="product_name">Nama Produk</label>
                                <InputText id="product_name" v-model="forms.product_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.product_name }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.product_name" >{{ errorAdd.product_name[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="ukuran_gramasi">Gramasi</label>
                                <InputText id="ukuran_gramasi" v-model="forms.ukuran_gramasi" required="true" autofocus :class="{ 'p-invalid': errorAdd.ukuran_gramasi }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.ukuran_gramasi" >{{ errorAdd.ukuran_gramasi[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="kg_per_ball">Kg/Ball</label>
                                <InputText id="kg_per_ball" type="number" min="0" v-model="forms.kg_per_ball" required="true" autofocus :class="{ 'p-invalid': errorAdd.kg_per_ball }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.kg_per_ball" >{{ errorAdd.kg_per_ball[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="pcs_per_ball">PCS/Ball</label>
                                <InputText id="pcs_per_ball" v-model="forms.pcs_per_ball" required="true" autofocus :class="{ 'p-invalid': errorAdd.pcs_per_ball }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.pcs_per_ball" >{{ errorAdd.pcs_per_ball[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="brand_id">Brand</label>
                                <Dropdown id="brand_id" v-model="forms.brand_id" :loading="loadingDropdownBrandAdd" :options="dataDropdownBrandAdd" :class="{ 'p-invalid': errorAdd.brand_id }" optionLabel="brand_name" optionValue="brand_id" placeholder="Pilih Brand" :filter="true" :showClear="true" @filter="searchDropdownBrand($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.brand_id" >{{ errorAdd.brand_id[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownBrand">Brand</label>
                                <Dropdown v-model="filters.brand_id" :loading="loadingDropdownBrandFilter" :options="dataDropdownBrandFilter" optionLabel="brand_name" optionValue="brand_id" placeholder="Pilih Brand" :filter="true" :showClear="true" @filter="searchDropdownBrand($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="userid" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="product_code" header="Kode Produk">
                        <template #body="slotProps">
                            {{slotProps.data.product_code}}
                        </template>
                    </Column>
                    <Column field="product_name" header="Nama Produk">
                        <template #body="slotProps">
                            {{slotProps.data.product_name}}
                        </template>
                    </Column>
                    <Column field="ukuran_gramasi" header="Gramasi">
                        <template #body="slotProps">
                            {{slotProps.data.ukuran_gramasi}}
                        </template>
                    </Column>
                    <Column field="kg_per_ball" header="Kg/Ball">
                        <template #body="slotProps">
                            {{slotProps.data.kg_per_ball}}
                        </template>
                    </Column>
                    <Column field="pcs_per_ball" header="PCS/Ball">
                        <template #body="slotProps">
                            {{slotProps.data.pcs_per_ball}}
                        </template>
                    </Column>
                    <Column field="brand_name" header="Brand">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_brand !== null"> 
                                {{slotProps.data.master_brand.brand_name}}
                            </div>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')" />
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="product_code">Kode Produk</label>
                        <InputText id="product_code" v-model="item.product_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.product_code }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.product_code" >{{ errorEdit.product_code[0] }}</small >
					</div>
					<div class="field">
						<label for="product_name">Nama Produk</label>
                        <InputText id="product_name" v-model="item.product_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.product_name }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.product_name" >{{ errorEdit.product_name[0] }}</small >
					</div>
					<div class="field">
						<label for="ukuran_gramasi">Gramasi</label>
                        <InputText id="ukuran_gramasi" v-model="item.ukuran_gramasi" required="true" autofocus :class="{ 'p-invalid': errorEdit.ukuran_gramasi }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.ukuran_gramasi" >{{ errorEdit.ukuran_gramasi[0] }}</small >
					</div>
					<div class="field">
						<label for="kg_per_ball">Kg/Ball</label>
                        <InputText id="kg_per_ball" type="number" min="0" v-model="item.kg_per_ball" required="true" autofocus :class="{ 'p-invalid': errorEdit.kg_per_ball }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.kg_per_ball" >{{ errorEdit.kg_per_ball[0] }}</small >
					</div>
					<div class="field">
						<label for="pcs_per_ball">PCS/Ball</label>
                        <InputText id="pcs_per_ball" v-model="item.pcs_per_ball" required="true" autofocus :class="{ 'p-invalid': errorEdit.pcs_per_ball }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.pcs_per_ball" >{{ errorEdit.pcs_per_ball[0] }}</small >
					</div>
					<div class="field">
                        <label for="brand_id">Brand</label>
                        <Dropdown id="brand_id" ref="dbrnd" v-model="item.brand_id" :loading="loadingDropdownBrandEdit" :options="dataDropdownBrandEdit" :class="{ 'p-invalid': errorEdit.brand_id }" optionLabel="brand_name" optionValue="brand_id" placeholder="Pilih Brand" :filter="true" :showClear="true" @filter="searchDropdownBrand($event,'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.brand_id" >{{ errorEdit.brand_id[0] }}</small>
					</div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownBrandAdd: false,
            loadingDropdownBrandEdit: false,
            loadingDropdownBrandFilter: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownBrandAdd: null,
            dataDropdownBrandEdit: null,
            dataDropdownBrandFilter: null,

            // addNew
            forms: {
                product_code: null,
                product_name: null,
                brand_id: null,
                ukuran_gramasi: null,
                kg_per_ball: null,
                pcs_per_ball: null,
            },

            // edit
            item: {},
            editItemDialog: false,
            
            // filter
            filters: {
                brand_id: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownBrand('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownBrand(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dbrnd.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownBrandFilter = true;
                }else if(purpose == "add"){
                    this.loadingDropdownBrandAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownBrandEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-brand',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownBrandFilter = res.data.data;
                        this.loadingDropdownBrandFilter = false;
                    }else if(purpose == "add"){
                        this.dataDropdownBrandAdd = res.data.data;
                        this.loadingDropdownBrandAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownBrandEdit = res.data.data;
                        this.loadingDropdownBrandEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownBrandFilter = res.data.data;
                        this.dataDropdownBrandAdd = res.data.data;
                        this.dataDropdownBrandEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-product/create',
                data: {
                    "product_code": this.forms.product_code,
                    "product_name": this.forms.product_name,
                    "brand_id": this.forms.brand_id,
                    "ukuran_gramasi": this.forms.ukuran_gramasi,
                    "kg_per_ball": this.forms.kg_per_ball,
                    "pcs_per_ball": this.forms.pcs_per_ball,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.product_code = null;
            this.forms.product_name = null;
            this.forms.brand_id = null;
            this.forms.kg_per_ball = null;
            this.forms.pcs_per_ball = null;
            this.forms.ukuran_gramasi = null;
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            // dropdown 
            this.searchDropdownBrand('', 'edit', item.master_brand.brand_name);

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-product/update',
                data: {
                    "brand_id" : this.item.brand_id,
                    "product_id" : this.item.product_id,
                    "product_code" : this.item.product_code,
                    "product_name" : this.item.product_name,
                    "ukuran_gramasi" : this.item.ukuran_gramasi,
                    "kg_per_ball" : this.item.kg_per_ball,
                    "pcs_per_ball" : this.item.pcs_per_ball,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-product',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "brand_id" : this.filters.brand_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-product/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "brand_id" : this.filters.brand_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Brand Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>